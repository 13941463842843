import { environment } from '@fundo/environments/environment';
import { PageId as TrackingPixelId } from '../shared/enums/page-id';

export enum HomeLinks {
  reviews = 'reviews',
  howItWorks = 'how-it-works',
  whyFundo = 'why-fundo',
  faqs = 'faqs',
  contact = 'contact',
}

export enum PageId {
  // Pages
  appComplete = 'app-complete',
  appDenied = 'app-denied',
  generalError = 'general-error',
  manualReview = 'manual-review',
  // notEligibleBanks = 'non-eligible-banks',
  // Terms
  // esign = 'e-sign',
  // messagingTerms = 'messaging-terms',
  // privacyPolicy = 'privacy-policy',
  // termsOfService = 'terms-of-service',
}

/**
 * Pages properties
 */
export interface PageConfig {
  id: PageId;
  link: string;
  // Layout settings
  showNavigation: boolean;
  linkOnFooter: boolean;
  trackingPixelId?: TrackingPixelId;
  // Page settings
  image?: 'error' | 'success';
  showHomeButton?: boolean | string;
  showCallButton?: boolean;
}

/**
 * Application STATIC pages
 */
export const staticPages: PageConfig[] = [
  // Pages
  {
    id: PageId.appComplete,
    link: `/${PageId.appComplete}`,
    showNavigation: false,
    linkOnFooter: false,
    trackingPixelId: TrackingPixelId.AppComplete,
    image: 'success',
    showHomeButton: true,
  },
  {
    id: PageId.appDenied,
    link: `/${PageId.appDenied}`,
    showNavigation: false,
    linkOnFooter: false,
    trackingPixelId: TrackingPixelId.ApplyformDenied,
    showHomeButton: false,
    showCallButton: true,
  },
  {
    id: PageId.generalError,
    link: `/${PageId.generalError}`,
    showNavigation: false,
    linkOnFooter: false,
    image: 'error',
    showHomeButton: true,
  },
  {
    id: PageId.manualReview,
    link: `/${PageId.manualReview}`,
    showNavigation: false,
    linkOnFooter: false,
    trackingPixelId: TrackingPixelId.ManualReview,
    // image: 'error',
    showHomeButton: true,
  }
];

/**
 * String list of pages which should be shown on footer
 */
export const defaultTemplateStaticPages = staticPages.filter(
  (pageConfig: PageConfig) => pageConfig.showNavigation,
);

export const formTemplateStaticPages = staticPages.filter(
  (pageConfig: PageConfig) => !pageConfig.showNavigation,
);
