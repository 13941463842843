/* eslint-disable prettier/prettier */
import { AgreementResponse } from '../services/types/customer';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { PlaidAssetsService } from '@fundo/app/features/plaid-integration-feature/services/plaid-assets.service';
import { LeadStatusResponse } from '@shared/enums/lead-status';
import { NavigationService } from '@shared/services/navigation.service';
import { DialogInfoComponent } from '@ui/dialogs/dialog-info/dialog-info.component';
import { Observable, of } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';

import { ClientCategory } from '../enums/client-category-enum';
import { CustomerService } from '../services/customer.service';
import { LeadService } from '../services/lead.service';
import { RedirectionService } from '../services/redirection/redirection.service';
import { NotificationService } from '../shared/dialogs/dialog-notification/services/notification.service';
import { ErrorKey } from '../shared/enums/error-response';
import { ErrorResponse } from '../shared/models/error-response';
import { DialogInfoId } from '../constants/dialogs';

@Injectable()
export class LeadStatusInterceptor implements HttpInterceptor {
  private dialogRef: any;

  constructor(
    private dialog: MatDialog,
    private leadService: LeadService,
    private navigationService: NavigationService,
    private customerService: CustomerService,
    private redirectionService: RedirectionService,
    private plaidAssetsService: PlaidAssetsService,
    private notificationService: NotificationService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      tap((event) => {
        if (event instanceof HttpResponse && !this.dialogRef) {
          if (request.url.match('lead/status')) {
            // Extract lead status response
            const response = event.body as LeadStatusResponse;
            
            const { isExpiredPlaidReport, userId, error, clientCategory } = response || {};

            // Handle expired Plaid report
            if (isExpiredPlaidReport) {
              this.openPlaidReportExpiredDialog(clientCategory);
              return;
            }

            // Segment analytics identification
            if(userId) {
              window.analytics?.identify(userId);
            }

            // Handle errors returned from the server
            if (error) {
              const { errorKey, confirmationToken } = error || {};
              this.handleErrors(errorKey, confirmationToken);
              return;
            }
          }
        }
      }),
    );
  }

  /**
   * Opens a dialog to inform the user about an expired Plaid report.
   * If confirmed, redirects the user to the bank verification page.
   */
  openPlaidReportExpiredDialog(clientCategoryId: number) {
    const actionBeforeClose = this.decidePlaidExpirationAction(clientCategoryId);
    
    const dialogType = 
      clientCategoryId === ClientCategory.Customer 
        ? DialogInfoId.plaidReportExpiredRefresh 
        :  DialogInfoId.plaidReportExpired;
    

    this.dialogRef = this.dialog
      .open(DialogInfoComponent, {
        data: { 
          type: dialogType, 
          actionBeforeClose
        },
        disableClose: true,
        backdropClass: 'dark-backdrop',
      })
      .afterClosed()
      .pipe(finalize(() => (this.dialogRef = undefined)));
  }

  /**
   * Decides which action should be taken after plaid report expiration.
   */
  decidePlaidExpirationAction(clientCategoryId: ClientCategory) {
    const action = clientCategoryId === ClientCategory.Customer
      ? this.plaidAssetsService.postUnderwritingPrepare({ isRefreshProcess: true, isExpiredPlaidReport: true })
      : this.leadService.redirectLeadToBankVerification();

    return action.pipe(
      catchError(
        () => of(this.notificationService.openGeneralErrorDialog()) 
      ),
      map((response) => !!response),
      tap((response: boolean) => {
        response && this.plaidExpirationNavigation(clientCategoryId);
      })
    )
  }

  /**
   * Navigates according to Client Category after Plaid Expiration.
   */
  private plaidExpirationNavigation(clientCategoryId: ClientCategory) {
    clientCategoryId === ClientCategory.Customer 
      ? this.navigationService.navigateToPlaidReportPreparationPage()
      : this.navigationService.navigateToCustomerInfo()
  }

  /**
   * Opens a dialog to inform the user about a same-day funding violation.
   * Redirects the user after clicking OK to validate the confirmation token.
   * @param confirmationToken The confirmation token associated with the same-day funding violation.
   */
  private openSameDayFundingViolationDialog(confirmationToken: string) {
    this.dialogRef = this.dialog.open(DialogInfoComponent, {
      data: { type: 'same-day-funding-violation' },
      disableClose: true,
    });

    // Redirect after clicking OK
    this.dialogRef
      .afterClosed()
      .pipe(finalize(() => (this.dialogRef = undefined)))
      .subscribe({
        next: () => this.validateSameDayFundingDialogConfirmation(confirmationToken),
      });
  }

  /**
   * Validates the confirmation token associated with a same-day funding violation.
   * Sends an agreement request to the server and handles the response accordingly.
   * @param confirmationToken The confirmation token to be validated.
   */
  private validateSameDayFundingDialogConfirmation(confirmationToken?: string): void {
    this.customerService.sendAgreement({ confirmationToken: confirmationToken }).subscribe({
      next: (res: AgreementResponse) => {
        const { errorKey, id } = res || {};

        if (id) {
          this.redirectionService.redirectAccordingToStatus(id);
        } else {
          this.handleErrors(errorKey);
        }
      },
      error: (err: ErrorResponse) => {
        const { errorKey, confirmationToken } = err?.error || {};
        this.handleErrors(errorKey, confirmationToken);
      },
    });
  }

  /**
   * Handles errors returned from the server.
   * Opens appropriate dialogs based on the error type.
   * @param errorKey The error key indicating the type of error.
   * @param confirmationToken The confirmation token associated with the error (if applicable).
   */
  private handleErrors(errorKey?: number, confirmationToken?: string) {
    if (errorKey === ErrorKey.SameDayFunding && confirmationToken) {
      this.openSameDayFundingViolationDialog(confirmationToken);
      return;
    }

    // Default Error message
    this.dialog.open(DialogInfoComponent, {
      data: { type: 'general-error' },
    });
  }
}
