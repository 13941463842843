import { bootstrapApplication } from '@angular/platform-browser';
import { getWebInstrumentations, initializeFaro } from '@grafana/faro-web-sdk';
import { TracingInstrumentation } from '@grafana/faro-web-tracing';

import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { environment } from './environments/environment';

initializeFaro({
  url: environment.grafanaFaroSettings.url,
  app: environment.grafanaFaroSettings.app,
  
  instrumentations: [
    // Mandatory, omits default instrumentations otherwise.
    ...getWebInstrumentations(),

    // Tracing package to get end-to-end visibility for HTTP requests.
    new TracingInstrumentation(),
  ],
});

bootstrapApplication(AppComponent, appConfig).catch((err) =>
  console.error(err),
);
