export enum DialogInfoId {
  generalError = 'general-error',
  ssnValidationFail = 'ssn-validation-fail',
  incompleteApp = 'incomplete-application',
  completeApp = 'complete-application',
  verifyingInfo = 'verifying-information',
  supportingDocuments = 'supporting-documents',
  plaidReportExpired = 'plaid-report-expired',
  plaidReportExpiredRefresh = 'plaid-report-expired-refresh',
  sameDayFundingViolation = 'same-day-funding-violation'
}

/**
 * Dialog properties
 */
export interface DialogInfoConfig {
  image: string | null;
  content: any;
  buttonLabel: string | null;
  hasSpinner?: boolean;
  isDocsInfo?: boolean;
}
