/*
 * Copyright The OpenTelemetry Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import { NoopLogger } from './NoopLogger';
var NoopLoggerProvider = /** @class */function () {
  function NoopLoggerProvider() {}
  NoopLoggerProvider.prototype.getLogger = function (_name, _version, _options) {
    return new NoopLogger();
  };
  return NoopLoggerProvider;
}();
export { NoopLoggerProvider };
export var NOOP_LOGGER_PROVIDER = new NoopLoggerProvider();
